import React, { Component } from 'react';
import PropTypes from 'prop-types';

import tree from '../../shared/images/svg/tree-simple.svg';
import iconClose from '../../shared/images/svg/icon-close.svg';
import arrowRight from '../../shared/images/svg/btn-arrow-right.svg';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

class Summary extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            data: props.params,
            contentData: props.contentData,
            header: props.dataheader,
            sectionAmount: props.sectionAmount,
            sectionCost: props.sectionCost,
            updated: false
        };
        this.handleInputCalculation = this.handleInputCalculation.bind(this);
        this.removeSectionFromStorage = this.removeSectionFromStorage.bind(this);
    }

    removeSectionFromStorage(sectionAmount, sectionCost, section) {
        localStorage.removeItem(sectionAmount);
        localStorage.removeItem(sectionAmount+"-inCart");
        localStorage.removeItem(sectionCost);
        localStorage.removeItem(section);

        var categories = [];
        var arr = [];
        var pathName = this.context.router.route.location.pathname;
        var sectionPath = "";
        if(pathName.includes('company')) {
            sectionPath = "company-";
            categories = this.state.contentData.company.categories;
        }
        else {
            sectionPath = "personal-";
            categories = this.state.contentData.private.categories;
        }

        var sectionAmountInLocalStorage;
        var sectionCostInLocalStorage;

        categories.forEach(category => {
            var categoryName = category.categoryName;
            sectionAmountInLocalStorage = sectionPath + categoryName + '-sectionAmount';
            sectionCostInLocalStorage = sectionPath + categoryName + '-sectionCost';
    
            var sectionAmountFound = localStorage.getItem(sectionAmountInLocalStorage);
            var sectionCostFound = localStorage.getItem(sectionCostInLocalStorage);
            var sectionAmountInCartFromStorage = localStorage.getItem(sectionAmountInLocalStorage+"-inCart") === "true" ? true : false ;
            if(sectionAmountFound && sectionCostFound && sectionAmountInCartFromStorage) {
                var addExistingSection = { amount: sectionAmountFound, cost: sectionCostFound };
                arr.push(addExistingSection);
            }
        });

        if(arr && arr.length > 0)
        {
            var totalAmount = 0;
            for(var y = 0; y < arr.length; y++) {
            totalAmount = totalAmount + Number(arr[y].amount);
            }
            localStorage.setItem('totalAmount', totalAmount);
            var totAmount;
            if(totalAmount < 1.0) {
            totAmount = Math.ceil(totalAmount);
            }
            else {
            totAmount = totalAmount;
            }

            var totalCost = ((totalAmount * this.getPriceFromPriceTable(totAmount))*1.25);
            localStorage.setItem('totalCost', totalCost);
        }
        else {
            localStorage.removeItem('totalCost');
            localStorage.removeItem('totalAmount');
        }

        this.setState((state) => {
            return {updated: true}
        });
    }

    getPriceFromPriceTable(amount) {
        var priceTableData = this.state.contentData.priceTable.tiers;
        var price = 0;
        priceTableData.forEach(tier => {
          if(tier.max !== -1 && tier.min <= amount && amount <= tier.max) {
            price = tier.price;
            return price;
          }
          else if(tier.min < amount && tier.max === -1) {
            price = tier.price;
            return price;
          }
        });
        return price;
    }
      
    handleInputCalculation(inputValue, inputTypeOfCalculation) {
        var amount;
        const dataheader = "summary";
        
        if(inputTypeOfCalculation === "add") {
          if(this.state.sectionAmount) {
            amount = this.state.sectionAmount + Math.ceil(inputValue);
          }
          else {
            amount = inputValue;
          }
        }        
        else if(inputTypeOfCalculation === "subtract") {
          if(this.state.sectionAmount) {
            amount = this.state.sectionAmount - Math.ceil(inputValue);
          }
          else {
            amount = inputValue;
          }
        }
        var ceiledAmount = Math.ceil(amount);
        var priceFromPriceTable = this.getPriceFromPriceTable(ceiledAmount)
        var cost = (ceiledAmount) * priceFromPriceTable;
        this.setState({ sectionAmount: ceiledAmount});
        this.setState({ sectionCost: cost })
        localStorage.setItem(dataheader+'-sectionAmount', ceiledAmount);
        localStorage.setItem(dataheader+'-sectionCost', cost);
    }

    renderCategories(categories, sectionPath, hrefPath) {
        var catArray = [];
        var cat2 = categories;
        var y = 0;
        cat2.forEach(category => {
            var categoryName = category.categoryName;
            var catTitle = category.title;
            if((sectionPath === "company" && categoryName === "manual") || (sectionPath === "private" && categoryName === "averageconsumer")) {
                let arrayItem = [<span key={['span', y].join('_')} className="btn-seperator"></span>,<a key={['anchor', y].join('_')} className="btn btn-category" href={categoryName}>{catTitle}</a>];
                catArray.push(arrayItem);
            }
            else {
                catArray.push(<a key={['anchor', y].join('_')} className="btn btn-category" href={categoryName}>{catTitle}</a>);
            }
            y++;
        });
        return catArray
    }

    renderCategorySummary(dataSections, removeSectionFromStorage, sectionPath, hrefPath) {
        let sections = [];
        let rows = [];
        let y = 0;

        let sectionAmountInLocalStorage;
        let sectionCostInLocalStorage;
        let sectionNameInLocalStorage;
        let categories = dataSections;
        let removeSection = removeSectionFromStorage;
        
        for (const category of categories) {
            let categoryName = category.categoryName;
            sectionAmountInLocalStorage = sectionPath + categoryName + '-sectionAmount';
            sectionCostInLocalStorage = sectionPath + categoryName + '-sectionCost';
            sectionNameInLocalStorage = sectionPath + categoryName; 

    
            let sectionAmountFound = localStorage.getItem(sectionAmountInLocalStorage);
            let sectionCostFound = localStorage.getItem(sectionCostInLocalStorage);
            let sectionFound = localStorage.getItem(sectionNameInLocalStorage);
            let sectionAmountInCartFromStorage = localStorage.getItem(sectionAmountInLocalStorage+"-inCart") === "true" ? true : false ;
            if(sectionNameInLocalStorage === "personal-averageconsumer" || sectionNameInLocalStorage === "personal-purchases") {
                sectionFound = true; //fix for empty section in avergageconsumer and personal-purchases.
            }
            if(sectionAmountFound && sectionCostFound && sectionFound && sectionAmountInCartFromStorage) {
                let fullHrefPath = hrefPath + category.categoryName;
                let addExistingSection = { title: category.title, amount: sectionAmountFound, cost: sectionCostFound, href: fullHrefPath, sectionAmountFromStorage: sectionAmountInLocalStorage, sectionCostFromStorage: sectionCostInLocalStorage, sectionFromStorage: sectionNameInLocalStorage };
                sections.push(addExistingSection);
            }
        }
                
        for (const section of sections) {
            let sectionCostIncVat = (Number(section.cost)*1.25); 
            let secTitle = "Gå till " + section.title;
            let sectionAmountFixed = Number(section.amount).toFixed(2);
            let sectionCostIncVatFixed = Number(sectionCostIncVat).toFixed(2);

            rows.push(
                <tr key={['trsummaryrow', y].join('_')}>
                    <td><a href={section.href} title={secTitle}>{section.title}</a></td>
                    <td className="u-text-right u-text-nowrap">{parseFloat(sectionAmountFixed).toLocaleString("sv")}</td><td className="u-text-right u-text-nowrap">{parseFloat(sectionCostIncVatFixed).toLocaleString("sv")} kr</td>
                    <td>
                        <div className="c-summary__remove-item" onClick={() => removeSection(section.sectionAmountFromStorage, section.sectionCostFromStorage, section.sectionFromStorage)}>
                            <img src={iconClose} alt="Ta bort" title="Ta bort denna rad" />
                        </div>
                    </td>
                </tr>
            );

            y++;
        }

        return rows;
    }

    renderTotalSummary(categories, sectionPath, hrefPath) {
        let totalCost = 0;
        let totalAmount = 0;
        let totalVat = 0;
        var sections = [];
        var sectionAmountInLocalStorage;
        var sectionCostInLocalStorage;
        categories.forEach(category => {
            var categoryName = category.categoryName;
            sectionAmountInLocalStorage = sectionPath + categoryName + '-sectionAmount';
            sectionCostInLocalStorage = sectionPath + categoryName + '-sectionCost';
    
            var sectionAmountFound = localStorage.getItem(sectionAmountInLocalStorage);
            var sectionCostFound = localStorage.getItem(sectionCostInLocalStorage);
            var sectionAmountInCartFromStorage = localStorage.getItem(sectionAmountInLocalStorage+"-inCart") === "true" ? true : false ;
            if(sectionAmountFound && sectionCostFound && sectionAmountInCartFromStorage) {
                var fullHrefPath = hrefPath + category.categoryName;
                var addExistingSection = { title: category.title, amount: sectionAmountFound, cost: sectionCostFound, href: fullHrefPath, sectionAmountFromStorage: sectionAmountInLocalStorage, sectionCostFromStorage: sectionCostInLocalStorage };
                sections.push(addExistingSection);
            }
        });
        sections.forEach(section => {
            var sectionCostIncVat = (Number(section.cost)*1.25);
            totalAmount = totalAmount + Number(section.amount);
            totalCost = totalCost + sectionCostIncVat;
        });
        var totalRoundedAmount;
        if(totalAmount < 1) {
            totalRoundedAmount = Math.ceil(totalAmount);
        }
        else {
            totalRoundedAmount = Math.round(totalAmount);
        }
        var priceFromPriceTable = this.getPriceFromPriceTable(totalRoundedAmount)
        totalCost = (((totalRoundedAmount * priceFromPriceTable) * 1.25));

        totalVat = (totalCost * 0.2);
        totalVat = totalVat.toFixed(2);

        var arr = [<tr  key="trtotal">
        <td><span>Avrundning*</span></td>
        <td className="u-text-right u-text-nowrap"><span>{parseFloat(totalRoundedAmount).toLocaleString("sv")}</span></td>
        <td className="u-text-right u-text-nowrap"><span>{parseFloat(totalCost).toLocaleString("sv")}</span></td>
        <td><span>&nbsp;</span></td>
        </tr>,
        <tr key="trrowfirst">
        <td><strong>Total</strong></td>
        <td colSpan="2" className="u-text-right u-text-nowrap"><strong>{parseFloat(totalCost).toLocaleString("sv")} kr</strong></td>
        <td>&nbsp;</td>
        </tr>,
        <tr key="trrowsecond" className="vat">
            <td colSpan="2">Varav moms 25%</td>
            <td className="u-text-right u-text-nowrap">{parseFloat(totalVat).toLocaleString("sv")} kr</td>
            <td>&nbsp;</td>
        </tr>,
        <tr key="trrowthird">
            <td colSpan="4"><small>*Avrundat till närmaste hela ton</small></td>
        </tr>];
        
        return arr;
    }

    render() {
        var foundAmount = localStorage.getItem('totalAmount');
        var totalAmount;
        if(foundAmount) {
            totalAmount = Number(foundAmount);
        }
        else {
            totalAmount = 0;
        }
        if(totalAmount < 1) {
            totalAmount = Math.ceil(totalAmount);
        }
        var calculatedPriceIncVat = (this.getPriceFromPriceTable(totalAmount) * 1.25);

        var categories = [];
        var pathName = this.context.router.route.location.pathname;
        var hrefPath = "";
        var sectionPath = "";
        if(pathName.includes('company'))
        {
            hrefPath = "/company/";
            sectionPath = "company-";
            categories = this.state.contentData.company.categories;
        }
        else {
            hrefPath = "/personal/";
            sectionPath = "personal-";
            categories = this.state.contentData.private.categories;
        }

        return (
            <div>
            <Header title={this.state.contentData.startPage.title} contentData={this.state.contentData}></Header>
            <div className="splash">
                <div className="content">
                    <div className="container">
                        <h1 className="site-title">{this.state.contentData.summaryPage.title}</h1>
                        <p className="lead">{this.state.contentData.summaryPage.description}</p>

                        <div className="c-summary">
                            <div className="c-summary__categories">
                                {this.renderCategories(categories, sectionPath, hrefPath)}
                            </div>

                            <div className="c-summary__body">
                                <h2 className="page-title"><span className="u-text-error">{Math.round(totalAmount)}</span> ton CO<sub>2</sub>e</h2>

                                <table className="c-table c-summary__table">
                                    <thead>
                                        <tr>
                                            <th>Kategori</th>
                                            <th className="u-text-right">Ton CO<sub>2</sub>e</th>
                                            <th className="u-text-right">Pris</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderCategorySummary(categories, this.removeSectionFromStorage, sectionPath, hrefPath)}
                                    </tbody>
                                    <tfoot>
                                        {this.renderTotalSummary(categories, sectionPath, hrefPath)}
                                    </tfoot>
                                </table>

                                <div className="c-summary__checkout">
                                    <a href={`//${this.state.contentData.cart.url}?with_product=${this.state.contentData.cart.productId}&price=${calculatedPriceIncVat}&quantity=${Math.round(totalAmount)}&customerType=${this.props.customerType}`} className="btn btn-cta btn-icon">
                                    Gå till betalning
                                    <svg className="c-icon c-icon-arrow" role="img">
                                        <use xlinkHref={`${arrowRight}#btn-right-right`}></use>
                                    </svg>
                                    </a>
                                </div>
                            </div>
                            
                            <div className="c-summary__media">
                                <img src={tree} alt="Ett träd" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
                </div>
            </div>
        );
    }
}

Summary.contextTypes = { 
    router: PropTypes.object, 
    location: PropTypes.object
};

export default Summary;